import { useState, useEffect, useRef } from "react";
import logo from "./../assets/images/logo-invert.png";

const Header = ({onClick}) => {
  const [header, setHeader] = useState("transparent");
  const toggleMenu = useRef();
  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return setHeader("header-transparent")
    } else if (window.scrollY > 70) {
      return setHeader("header-dark")
    } 
  }
  
  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
    return () =>
      window.removeEventListener('scroll', listenScrollEvent);
  }, []);
  
  return(
    <header className={header}>
      <div className="header-logo">
        <img src={logo} style={{height: "100%"}}/>
      </div>
      <div id="menuToggle">
        <input type="checkbox" ref={toggleMenu}/>
        <span></span>
        <span></span>
        <span></span>
        <ul id="menu">
          <li className="subtitle" onClick={()=>{onClick("about");  toggleMenu.current.click()}}>
              O nama
          </li>
          <li className="subtitle" onClick={()=>{onClick("association");  toggleMenu.current.click()}}>
              Asocijacija kreativaca
          </li>
          <li className="subtitle" onClick={()=>{onClick("participate");  toggleMenu.current.click()}}>
              Učestvujte
          </li>
          <li className="subtitle" onClick={()=> {onClick("contact");  toggleMenu.current.click()}}>
              Kontakt
          </li>
        </ul>
      </div>
      <nav className="nav">
        <span className="subtitle" onClick={()=>onClick("about")}>
          O nama
        </span>
        <span className="subtitle" onClick={()=>onClick("association")}>
          Asocijacija kreativaca
        </span>
        <span className="subtitle" onClick={()=>onClick("participate")}>
          Učestvujte
        </span>
        <span className="subtitle" onClick={()=> onClick("contact")}>
          Kontakt
        </span>
      </nav>
    </header>
  );
}

export default Header;