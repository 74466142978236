const Announcement = ({img, className}) => {
  return(
      <div 
        className={(className ? className : "") + ("announcement-container")}
      >
      <img src={img}/>
    </div>
  )
}

export default Announcement;