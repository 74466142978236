import fb from "./../assets/images/fb.svg";
import ig from "./../assets/images/ig.svg";
import li from "./../assets/images/li.svg";
import phone from "./../assets/images/phone.svg";
import mail from "./../assets/images/mail.svg";
import logo from "./../assets/images/logo-invert.png";

const Footer = ({innerRef}) => {
  return(
    <footer className="background-dark" ref={innerRef}>
      <div className="contact">
        <span className="subtitle">Kontakt</span>
          <span className="contact-items email-phone-container">
            <span className="contact-item email-phone">
              <img src={mail} className="contact-icon"/>
              <span>info@luckymarket.ba</span>
            </span>
            <span className="contact-item email-phone">
              <img src={phone} className="contact-icon"/>
              <span>+387603006273</span>
            </span>   
        </span>
      </div>
      <hr />
      <div className="contact">
        <div className="contact-item">
          <span className="subtitle">Zapratite nas na društvenim mrežama</span>
          <div className="contact-items">
            <a 
              href="https://www.facebook.com/LuckyMarket/" 
              className="social"
              target="_blank"
            >
              <img src={fb} />
            </a>
            <a 
              href="https://www.instagram.com/luckymarketsarajevo/" 
              className="social"
              target="_blank"
            >
              <img src={ig} />
            </a>
          </div>
        </div>
      </div>
      <hr />
      <div className="contact">
        <span className="design-and-code">Design & code by: 
          <a 
            className="social" 
            href="https://www.linkedin.com/in/zana-garaplija-8199511b9/"
            target="_blank"
          >
            <img src={li}/>
          </a>
        </span>
      </div>
      <hr />
      <div className="contact copyright">
        <img src={logo} className="footer-logo"/>
      © Lucky Market 2022
      </div>
    </footer>
  );
}

export default Footer;