import { useState, useRef, useEffect } from "react";
import emailjs from '@emailjs/browser';

const Form = ({innerRef}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [isNameValid, setIsNameValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isHobbieValid, setIsHobbieValid] = useState(true);

  const form = useRef();
  const name = useRef('');
  const email = useRef('');
  const phone = useRef('+387');
  const hobbie = useRef('');
  const website = useRef('');

  let nameRegex= /^[a-zA-Z]+(?:\s[a-zA-Z]+)+$/;
  let emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  let phoneRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{3})(?: *x(\d+))?\s*$/;

  const validateForm = () => {
    setIsNameValid(nameRegex.test(name.current.trim()));
    setIsEmailValid(emailRegex.test(email.current.trim()));
    setIsPhoneValid(phoneRegex.test(phone.current.trim()));
    setIsHobbieValid(hobbie.current.trim() !== '');
    setIsFormValid(nameRegex.test(name.current.trim()) && emailRegex.test(email.current.trim()) && phoneRegex.test(phone.current.trim()) && hobbie.current.trim() !== '');
  }

  const sendEmail = (e) => {
    e.preventDefault();
    validateForm();
    let isFormReallyValid = nameRegex.test(name.current.trim()) && emailRegex.test(email.current.trim()) && phoneRegex.test(phone.current.trim()) && hobbie.current.trim() !== '';
    if(isFormReallyValid === true) {
      setIsLoading(true)
      setIsButtonDisabled(true)
      emailjs.sendForm( "contact_form",
      "template_tgm2vo3",
      form.current,
      "user_HI1enShe8TsEkAhzcV3u7")
      .then((result) => {
        form.current.reset();
        setIsButtonDisabled(true);
        setIsSent(true);
        setIsLoading(false);
        console.log(result.text);
      }, (error) => {
        setIsLoading(false);
        console.log(error.text);
      });
    }
    
    
    
  };

  return(
    <div ref={innerRef} className="form-container">
      <form ref={form} className="form background-light" onSubmit={(e)=>sendEmail(e)}>
      <div id="participate" className="title">Želite učestvovati?</div>
      <div id="sign-up" className="p-subtitle">Prijavite se:</div>
        <input
          type="text"
          placeholder="Ime i prezime"
          name="name"
          value={name.current.value}
          onChange={e => name.current = e.target.value}
          autoComplete="off"
          id="name"
        />
        {
          !isFormValid && !nameRegex.test(name.current.trim()) &&
          <div className="reminder">Unesite ime i prezime.</div>
        }
        <input
          type="text"
          placeholder="Email"
          autoComplete="off"
          value={email.current.value}
          onChange={e => email.current = e.target.value}
          name="email"
          id="email"
        />
        {
          !isFormValid && !emailRegex.test(email.current.trim()) &&
          <div className="reminder">Unesite e-mail.</div>
        }
        <input
          placeholder="Broj telefona"
          autoComplete="off"
          name="phone"
          defaultValue={"+387"}
          value={phone.current.value}
          onChange={e => phone.current = e.target.value}
          id="phone"
        />
        {
          !isFormValid && !phoneRegex.test(phone.current.trim()) &&
          <div className="reminder">Unesite broj telefona.</div>
        }
        <input
          type='text'
          placeholder='Vaš hobi'
          autoComplete="off"
          name="message"
          value={hobbie.current.value}
          onChange={e => hobbie.current = e.target.value}
          id="message"
        /> 
        {
          !isFormValid && hobbie.current.trim() === '' &&
          <div className="reminder">Unesite hobi.</div>
        }
        <input
          type='text'
          placeholder='Website / link na vaš rad (opcionalno)'
          name="website"
          autoComplete="off"
          id="website"
          value={website.current.value}
          onChange={e => website.current = e.target.value}
        />             
        <button 
          type="submit"       
          className={isButtonDisabled ? "disabled": ""}>
            {isLoading ? "Šaljem..." :
              isSent ? 
              "Poslano!" :
              "Pošalji"
            }
        </button>
      </form>
    </div>
   
  )
}

export default Form;