const Hero = () => {
  return(
    <div className="hero-container">
      <span className="fancy-title">
          Lucky Market
      </span>
      <span className="hero-title">Dobrodošli!</span>
    </div>
  );
}

export default Hero;