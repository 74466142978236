import { useRef } from "react";
import Header from "./Header";
import Hero from "./Hero";
import Form from "./Form";
import Footer from "./Footer";
import Paragraph from "./Paragraph";
import Parallax from "./Parallax";
import TwoImages from "./TwoImages";
import plakat from "./../assets/images/plakat.jpeg";
import lucky2 from "./../assets/images/lucky2.jpeg";
import lucky3 from "./../assets/images/lucky3.jpg";
import lucky4 from "./../assets/images/lucky4.png";
import asocijacija from "./../assets/images/asocijacija.jpg";
import aida from "./../assets/images/aidakorman.jpeg";
import Announcement from "./Announcement";

const Home = () => {

  const aboutRef = useRef();
  const associationRef = useRef();
  const participateRef = useRef();
  const contactRef = useRef();

  const clickHandler = (item) => {
    switch(item) {
      case "about":
        aboutRef.current.scrollIntoView({ behavior: "smooth", block: "start",
        inline: "start" });
        break;
      case "association":
        associationRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "participate":
        participateRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "contact":
        contactRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        // code block
    }
  }

 return(
   <div>  
    <Header onClick={(item)=>clickHandler(item)}/>
    <Hero />
    <div id="current-announcements" className="title">
        <a id="participate" onClick={()=> clickHandler("participate")}>OTVORENE PRIJAVE</a><span> ZA LUCKY MARKET ISPRED ARIA MALL-A</span>
        <span className="title">23-30.04.2024.</span> 
    </div>
    <Announcement 
      img={plakat}
    />
    <Paragraph 
      className="background-blue "
      innerRef={aboutRef}
      title={"Since 2011"} 
      subtitle={"Mjesto susreta kreativaca i razmjene ideja"}
      text={"Ako želite posjetiti jedan živi, vibrirajući, šareni, multikulturalni, uzbudljiv i drugačiji događaj sa izlagačima i posjetiocima koji dolaze ne samo iz Sarajeva, nego i iz cijele BiH, Lucky Market je mjesto za Vas."}
    />
    <Parallax id="parallax-1"/>
    <Paragraph
      className="background-wow "
      title={"Ideja Lucky Marketa..."}
      image={lucky2}
      text={"...nadahnuta je svjetski poznatim marketima, društveno korisnim i ekološkim, te na njemu možete nabaviti sve od suvenira do rahat lokuma. LM nudi “novu kreativnost”, fuziju kultura, zanata, umjetnosti i dizajna na jednom mjestu."}
    /> 
    <Paragraph
      title={"Cool shopping destinacija"}
      text={"U razgovoru s kreativcima možete saznati zbog čega su ludi za onim čime se bave, a pri tome pazariti neki unikatan komad nakita, cutting edge dizajnerske odjeće, jedinstvene suvenire i poklone, dječiju garderobu i igračke, lampe itd; a pri tome pomoći kreativcima da plasiraju proizvod i nastave svoj rad."}
    /> 
    <TwoImages 
      img1={lucky4}
      img2={lucky3}
    />
    <Paragraph
      title={"Lovci na jeftino?"}
      subtitle={"Također će biti zadovoljni."}
      className="background-peach "
      text={"A sve ono što ne budu našli tu na jednom mjestu, moći će naručiti kod Kreativaca."}
    />
    <Paragraph 
      innerRef={associationRef}
      className="background-dark "
      title={"Asocijacija kreativaca"}
      image={asocijacija}
      text={"Asocijaciju kreativaca osnovala je 2010. godine BH modna dizajnerica Aida Korman. Nakon Aidinog dugogodišnjeg rada na promociji BH kulture kroz modu i dizajn, odlučila je da okupi sve one koji se bave proizvodnjom Made in BiH."}
    />
    <Paragraph
      aida
      className="background-blue "
      title={"Aida Korman"}
      image={aida}
      subtitle={"Osnivačica Asocijacije kreativaca"}
      text={"Aida Korman je bh modna dizajnerica rođena u Sarajevu gdje je i upisala studij produkt dizajna 1997, a iduće godine nastavlja ga u Rimu na Evropskom Institutu za Dizajn, odsjek modni dizajn, te tu stiče diplomu. Po završetku studija radila je za Alexandra McQueena u Londonu. Nakon određenog stečenog iskustva odlučila se posvetiti vlastitom kreatorskom izražaju kojim se bavi i danas."}
    />
    <Form innerRef={participateRef}/>
    <Footer innerRef={contactRef}/>
   </div>

 )
}

export default Home;