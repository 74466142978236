const Paragraph = ({innerRef, aida, className, title, subtitle, text, image}) => {
  return(
    <div 
      ref={innerRef}
      className={(className ? className : "") + (image ? "p-with-image-container " : "") + "p-container"}
    >
      <div 
        className={"paragraph " + "p-text-only " + (image ? "p-with-image" : "")}
      >
        <span className="title">{title}</span>
        <span className="p-subtitle">{subtitle}</span>
        <span className="text">{text}</span>
        {
         aida &&
          <span className="text aida">Posjetite njen <a target="_blank" href="https://aidakorman.com/" >webshop</a>.</span> 
        }
      </div>
      { image && 
        <div className="p-image-container">
          <img src={image}></img>
        </div>
      }
    </div>
  );
}

export default Paragraph;