const TwoImages = ({img1, img2}) => {
  return(
      <div className="twoImages">
        <div>
          <img src={img1} />
        </div>
        <div>
          <img src={img2} />
        </div>
      </div>
  )
}

export default TwoImages;